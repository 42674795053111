import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

import Countdown from 'react-countdown';
import ReactTimeAgo from 'react-time-ago'

const CountWrap = ({ label, value }: { label: string; value: string }) => {
  return (
    <Text as='span'>
      {value}
      <span className='timeColor'>{label}</span>
    </Text>
  );
};

const CountdownComp: FC<{ dateTime: Date | undefined }> = ({ dateTime }) => {
  const Completionist = () => dateTime ? <ReactTimeAgo date={dateTime} locale="en-US" /> : null
  if (!dateTime) return <Box>-</Box>

  // Renderer callback with condition
  const renderer = ({ hours, days, minutes, seconds, completed }: any) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <>
          <CountWrap label='h' value={hours} />

          <CountWrap label='m' value={minutes} />

          <CountWrap label='s' value={seconds} />
        </>
      );
    }
  };

  return <Countdown date={dateTime} renderer={renderer} />;
};

export default CountdownComp;

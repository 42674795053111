import {
  Flex,
  Text,
  Stack,
  Button,
  Image,
  HStack,
  Box,
  Heading,
  Container,
  WrapItem,
  Wrap,
  Center,
  ListItem,
  UnorderedList,
  GridItem,
  SimpleGrid,
  Spinner,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import Logo from './../assets/Logo.svg';
import PlaceABid from './../assets/PlaceABid.svg';
import NumberFormat from 'react-number-format';
import CountdownComp from './Countdown';
import { useWeb3React } from '@web3-react/core';
import useConnect from '../hooks/useConnect';
import { useAuctionState, useBid, useWithdraw } from '../hooks/useAuction';

const formatAccount = (account: string) => {
  return account.substring(0, 5) + '...' + account.substring(account.length - 2, account.length)
}

export const Auction = () => {
  const [renderButton, setRenderButton] = useState(false);
  const [value, setValue] = useState('');
  const [numericalValue, setNumericalValue] = useState<string>();
  const toast = useToast()

  const { account } = useWeb3React()
  const { connect, switchToSongbird, isUnsupportedChainIdError } = useConnect()

  const onConnectClick = useCallback(() => {
    if (isUnsupportedChainIdError) {
      switchToSongbird()
    } else connect()
  }, [connect, switchToSongbird, isUnsupportedChainIdError])

  const buttonText = useMemo(() => {
    if (account) {
      return formatAccount(account)
    } else if (isUnsupportedChainIdError) {
      return 'Switch to Songbird'
    } else {
      return 'Connect'
    }
  }, [account, isUnsupportedChainIdError])

  const { highestBid, highestBidder, auctionEndTime, pendingReturns, invited, update } = useAuctionState()
  const bid = useBid()

  const auctionHasEnded = auctionEndTime !== undefined && (auctionEndTime * 1000 < Number(new Date()))

  const [txLoading, setTxLoading] = useState(false)

  const auctionEndDate = useMemo(() => auctionEndTime ? new Date(auctionEndTime * 1000) : undefined, [auctionEndTime])

  const minBid = useMemo(() => {
    if (highestBid === undefined) return undefined
    else return highestBid + 100
  }, [highestBid])

  const onBid = useCallback(() => {
    const num = Number(value)
    if (isNaN(num)) {
      return
    }
    const f = async () => {
      try {
        const tx = await bid(num)
        if (!tx) return
        setTxLoading(true)
        await tx.wait()
        toast({
          title: 'Bid successful.',
          description: "You have the highest bid.",
          status: 'success',
          duration: 6000,
          isClosable: true,
        })
        setTxLoading(false)
        update()
      } catch (e) {
        setTxLoading(false)
      }
    }
    f()
  }, [value, bid, update])

  return (
    <Box overflow={{ base: 'hidden' }} h='100vh'>
      <Box
        width={'full'}
        minHeight={'400px'}
        backgroundImage={require('./../assets/Bg1.jpg')}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        px={{ md: 8 }}
        position={'sticky'}
        pt={8}
      >
        {/* Navbar */}
        <Box px={4}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <Flex
              justifyContent={'center'}
              alignItems='center'
              bg={'#E9E2E6'}
              rounded='full'
              p={4}
            >
              <Image src={Logo} borderWidth='1px' w='35px' />
            </Flex>

            <Flex>
              <Stack direction={'row'}>
                <Button
                  bg={'#E9E2E6'}
                  variant='outline'
                  rounded='full'
                  py={8}
                  px={10}
                  textTransform='uppercase'
                  fontSize={'17px'}
                  border='2px solid white'
                  onClick={() => {
                    onConnectClick()
                  }}
                >
                  {buttonText}
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Box>

        {renderButton && (
          <Container maxW={'480px'} py={[12, 24]}>
            <Wrap w='full' spacing={2}>
              <WrapItem
                w='full'
                h='80px'
                bg={'gray.100'}
                borderRadius={20}
                px={8}
                justifyContent='center'
                alignItems={'center'}
              >
                <HStack h='100%'>
                  <HStack>
                    <NumberFormat
                      value={numericalValue}
                      displayType={'input'}
                      thousandSeparator={true}
                      autoFocus
                      style={{
                        fontFamily: 'Roobert',
                        fontSize: '24px',
                        paddingRight: '20px',
                        textAlign: 'right',
                        maxWidth: '250px',
                        height: '80PX',
                        border: 0,
                        outline: 'none',
                        backgroundColor: '#EDF2F7',
                      }}
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setValue(value);
                        setNumericalValue(formattedValue);
                      }}
                    />

                    <Text fontSize='2xl'>|</Text>
                  </HStack>

                  <Text fontWeight={'bold'} fontSize='2xl'>
                    SGB
                  </Text>
                </HStack>
              </WrapItem>

              <WrapItem w='full'>
                <Tooltip
                  label={invited ? `Minimum bid ${minBid}` : 'Unable to bid with the connected account (no invite or delegation)'}
                  isDisabled={invited && highestBid !== undefined && Number(value) >= highestBid + 100}
                >
                  <Button
                    className='place-bid-button'
                    color={'white'}
                    variant='outline'
                    borderRadius={25}
                    height='80px'
                    maxW={'xl'}
                    width={{ md: 'full' }}
                    minW={'100%'}
                    onClick={onBid}
                    _hover={{ textDecoration: 'none', opacity: '.9' }}
                    _focus={{ textDecoration: 'none', opacity: '.9' }}
                    _active={{
                      textDecoration: 'none',
                    }}
                    size='lg'
                    fontSize={'2xl'}
                  >
                    <Image src={PlaceABid} w='40px' mr={3} />
                    {txLoading ? <Spinner /> : 'Place A Bid'}
                  </Button>
                </Tooltip>
              </WrapItem>
            </Wrap>
          </Container>
        )}
      </Box>

      <Container
        maxW='480px'
        color='black'
        transform='translateY(-60px)'
        zIndex={10}
      >
        {/* Top */}
        <SimpleGrid
          columns={2}
          py={5}
          border='2px solid white'
          spacing={4}
          borderRadius='40px'
          // bg='#ffe7ee'
          bgGradient='linear(to-b, #FEFAEF, #FEE7EF)'
          borderColor={'#f0f2f7'}
          w='full'
        >
          <GridItem textAlign='center'>
            <Text fontWeight='semibold' as='h4' mb={2} fontSize={'17px'}>
              Highest Bid
            </Text>

            <Center>
              <Text
                bg='gray.100'
                py={4}
                px={6}
                fontSize={'17px'}
                borderRadius={15}
                minW={'130px'}
                border={highestBidder === account ? '1px solid #1daa5d' : 0}
              >
                {highestBid !== undefined ?
                  <NumberFormat
                    value={highestBid}
                    displayType={'text'}
                    suffix={' SGB'}
                    thousandSeparator={true}
                    autoFocus
                    style={{
                      fontSize: '17px',
                      border: 0,
                      outline: 'none',
                      backgroundColor: '#EDF2F7',
                    }}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setValue(value);
                      setNumericalValue(formattedValue);
                    }}
                  />
                  : '-'}
              </Text>
            </Center>
          </GridItem>

          <GridItem textAlign='center'>
            <Text fontWeight='semibold' as='h4' mb={2} fontSize={'17px'}>
              Auction Ends In
            </Text>
            <Center>
              <HStack
                bg='gray.100'
                py={4}
                px={6}
                fontSize={'17px'}
                borderRadius={15}
                spacing={1}
              >
                <CountdownComp dateTime={auctionEndDate} />
              </HStack>
            </Center>
          </GridItem>
        </SimpleGrid>

        <Box
          overflowY={{ base: 'auto', md: 'auto' }}
          maxHeight={'50vh'}
          className='hideScroll'
        >
          <Heading as='h3' fontSize={'4xl'} mt={6} pb={6}>
            First Villa
          </Heading>

          <HStack spacing={6}>
            <HStack spacing={2} fontSize={'lg'} my='auto'>
              <Image
                borderRadius='full'
                px={2}
                py={2}
                bg='gray.100'
                boxSize='40px'
                src={require('./../assets/ArtistIcon.png')}
              />
              <Text fontSize={'19px'}>3D Model</Text>
            </HStack>

            <HStack spacing={2} my='auto'>
              <Image
                borderRadius='full'
                fit={'cover'}
                boxSize='40px'
                borderWidth={'1px'}
                src={require('./../assets/Bg2.jpg')}
              />
              <Text fontSize={'19px'}>KINDI</Text>
            </HStack>
          </HStack>

          <Box pb='260px'>
            <Text fontSize='19px' pt={4}>
              First Villa is a Modern Villa Designed for a Standard size parcel
              in NetVRk
            </Text>
            <Text fontSize='19px' pt={4}>
              Ground floor:
            </Text>
            <UnorderedList>
              <ListItem fontSize={'19px'}>Home Office</ListItem>
              <ListItem fontSize={'19px'}>Multi-Purpose Room</ListItem>
              <ListItem fontSize={'19px'}>Double-Height Living Room</ListItem>
              <ListItem fontSize={'19px'}>Open Kitchen</ListItem>
              <ListItem fontSize={'19px'}>Dining Area</ListItem>
            </UnorderedList>
            <Text fontSize='19px' pt={4}>
              First Floor:
            </Text>
            <UnorderedList>
              <ListItem fontSize={'19px'}>Upper Living Room</ListItem>
              <ListItem fontSize={'19px'}>
                2 Master Bedrooms + Balcony{' '}
              </ListItem>
              <ListItem fontSize={'19px'}>3 Bedrooms </ListItem>
            </UnorderedList>

            <Text fontSize='19px' pt={4}>
              Outdoor Area:
            </Text>
            <UnorderedList>
              <ListItem fontSize={'19px'}>1 Parking Lot</ListItem>
              <ListItem fontSize={'19px'}>
                Swimming Pool + Built-In Sitting Area
              </ListItem>
            </UnorderedList>

            <Text fontSize='19px' pt={4}>
              The actual 3D model that is auctioned will be handed over to the
              owner of this auctioned Songbird NFT when the Netvrk Network
              launches. The Netvrk Asset holder can build the Villa in the
              Metaverse of Netvrk and it can be build in the real world as well.
            </Text>

            <Text fontSize='19px' pt={4}>
              In case the owner wants to build it in the real world he needs to
              hire a contractor and pay for the construction. This is not
              included in the auction.
            </Text>
            <Text fontSize='19px' pt={4}>
              In case the owner wants it to be build in the Metaverse the owner
              would have to buy a standardsize plot of land from Netvrk in order
              to place this Villa.
            </Text>
            <Text fontSize='19px' pt={4}>
              The owner of the “First Villa” can always chose to sell his NFT
              Villa in which case someone else can build it on his or her own
              plot of land in the Metaverse.
            </Text>
          </Box>
        </Box>

        <Box
          maxW='480px'
          position={'sticky'}
          bottom='-60px'
          left={0}
          right={0}
          bg='white'
          py='6'
          px={{ base: 0, md: 1 }}
        >
          <PlaceBidButton pendingReturns={pendingReturns} auctionEnded={auctionHasEnded} renderButton={renderButton} setRenderButton={setRenderButton} updateState={update} />
        </Box>
      </Container >

    </Box >
  );
};

interface PlaceBidButtonProps {
  pendingReturns: number | undefined,
  auctionEnded: boolean,
  renderButton: boolean,
  setRenderButton: React.Dispatch<React.SetStateAction<boolean>>
  updateState: () => Promise<void> | undefined
}

const PlaceBidButton: React.FC<PlaceBidButtonProps> = ({ pendingReturns, auctionEnded, renderButton, setRenderButton, updateState }) => {
  const withdraw = useWithdraw()
  const [loading, setLoading] = useState(false)
  const { account } = useWeb3React()
  const toast = useToast()

  const onWithdraw = useCallback(() => {
    const f = async () => {
      try {
        const tx = await withdraw()
        if (!tx) return
        setLoading(true)
        await tx.wait()
        toast({
          title: 'Withdraw successful.',
          description: "Your previous bids were withdrawn.",
          status: 'success',
          duration: 6000,
          isClosable: true,
        })
        await updateState()
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    f()
  }, [withdraw, toast, updateState])

  if (pendingReturns !== undefined && pendingReturns > 0) {
    return (
      <Button
        className='cancel-bid-button'
        color={'white'}
        variant='outline'
        borderRadius={25}
        height='80px'
        maxW={'xl'}
        width={{ md: 'full' }}
        minW={'100%'}
        _hover={{ textDecoration: 'none', opacity: '.8' }
        }
        _focus={{ textDecoration: 'none', opacity: '.8' }}
        onClick={onWithdraw}
        fontSize={'md'}
      >
        <Text width="100%" whiteSpace={'normal'}>
          {auctionEnded ?
            'Auction has ended, click here to withdraw your funds'
            : "You've been outbid please click here to withdraw your position before making a new bid"
          }
        </Text>
      </Button >
    )
  }

  return (
    <Box>
      {renderButton ? (
        <Button
          className='cancel-bid-button'
          color={'white'}
          variant='outline'
          borderRadius={25}
          height='80px'
          maxW={'xl'}
          width={{ md: 'full' }}
          minW={'100%'}
          _hover={{ textDecoration: 'none', opacity: '.8' }
          }
          _focus={{ textDecoration: 'none', opacity: '.8' }}
          onClick={() => setRenderButton(false)}
          fontSize={'2xl'}
        >
          Cancel
        </Button >
      ) : (
        <Button
          className='place-bid-button'
          bg='linear-gradient(90deg,rgb(82, 204, 213) 0%,rgb(115, 230, 238) 100)'
          color={'white'}
          variant='outline'
          borderRadius={25}
          height='80px'
          maxW={'xl'}
          disabled={account === undefined}
          width={{ md: 'full' }}
          minW={'100%'}
          onClick={() => setRenderButton(true)}
          _hover={{ textDecoration: 'none', opacity: '.8' }}
          _focus={{ textDecoration: 'none', opacity: '.8' }}
          _active={{
            textDecoration: 'none',
          }}
          size='lg'
          fontSize={'2xl'}
        >
          <Image src={PlaceABid} w='40px' mr={3} />
          Place A Bid
        </Button>
      )}
    </Box >
  )
}
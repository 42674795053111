import { useWeb3React } from "@web3-react/core"
import { ethers } from "ethers"
import { formatEther, parseEther } from "ethers/lib/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { VAuction__factory } from "../types/ethers-contracts"

export const auctionAddress = process.env.REACT_APP_AUCTION_ADDRESS
if (!auctionAddress) throw new Error("Auction address undefined")

const useAuction = () => {
  const { library } = useWeb3React<ethers.providers.JsonRpcProvider>()

  const auction = useMemo(() => {
    if (!library) return undefined
    return VAuction__factory.connect(auctionAddress, library.getSigner())
  }, [library])

  return auction
}

export const useBid = () => {
  const auction = useAuction()

  const bid = useCallback((amount: number) => {
    if (!auction) return undefined
    return auction.bid({ value: parseEther(amount.toString()) })
  }, [auction])

  return bid
}

export const useHighestBid = () => {
  const auction = useAuction()
  const [highestBid, setHighestBid] = useState<number>()

  const update = useCallback(() => {
    if (!auction) return undefined
    const f = async () => {
      const b = formatEther(await auction.highestBid())
      setHighestBid(Number(b))
    }
    f()
  }, [auction])

  useEffect(() => {
    update()
  }, [update])

  return { highestBid, update }
}

export const useAuctionEndTime = () => {
  const auction = useAuction()
  const [auctionEndTime, setAuctionEndTime] = useState<number>()

  const update = useCallback(() => {
    if (!auction) return undefined
    const f = async () => {
      const e = await auction.auctionEndTime()
      setAuctionEndTime(e.toNumber())
    }
    f()
  }, [auction])

  useEffect(() => {
    update()
  }, [update])

  return { auctionEndTime, update }
}

export const usePendingReturns = (account: string | undefined) => {
  const auction = useAuction()
  const [pendingReturns, setPendingReturns] = useState<number>()

  const update = useCallback(() => {
    if (!auction) return undefined
    const f = async () => {
      if (!account) return
      const r = await auction.pendingReturns(account)
      setPendingReturns(Number(formatEther(r)))
    }
    f()
  }, [auction, account])

  useEffect(() => {
    update()
  }, [update])

  return { pendingReturns, update }
}

export const useAuctionState = () => {
  const { account } = useWeb3React()
  const auction = useAuction()

  const [pendingReturns, setPendingReturns] = useState<number>()
  const [auctionEndTime, setAuctionEndTime] = useState<number>()
  const [highestBid, setHighestBid] = useState<number>()
  const [highestBidder, setHighestBidder] = useState('')
  const [invited, setInvited] = useState(true)

  const update = useCallback(() => {
    if (!auction) return undefined
    const f = async () => {
      if (!account) return
      const [highestBidBn, highestBidder, auctionEndTimeBn, pendingReturnsBn, hasInvite] = await auction.getState(account)
      setHighestBid(Number(formatEther(highestBidBn)))
      setHighestBidder(highestBidder)
      setAuctionEndTime(auctionEndTimeBn.toNumber())
      setPendingReturns(Number(formatEther(pendingReturnsBn)))
      setInvited(hasInvite)
    }
    return f()
  }, [auction, account])

  useEffect(() => {
    update()
  }, [update])

  return { highestBid, highestBidder, auctionEndTime, pendingReturns, invited, update }
}

export const useWithdraw = () => {
  const auction = useAuction()

  const withdraw = useCallback(() => {
    return auction?.withdraw()
  }, [auction])

  return withdraw
}
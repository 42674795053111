import './App.css';

import { ChakraProvider } from '@chakra-ui/react';
import { Auction } from './components/Auction';
import { customTheme } from './theme';

import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from "ethers";

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)

function getLibrary(provider: any, connector: any) {
  return new ethers.providers.Web3Provider(provider)
}

export const App = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Auction />
      </Web3ReactProvider>
    </ChakraProvider>
  );
};
